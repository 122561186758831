import React from 'react';
import styled from 'styled-components';
import PrivacyContent from '../../Components/PrivacyContent';

function Privacy(props) {
  return (
    <Wrapper>
      <div className="content">
        <PrivacyContent />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  background-color: #f0f0f0;

  & > .content {
    max-width: var(--contentMaxWidth);
    padding: 20px;
    margin: 0 auto;
    background-color: #fff;
  }
`;

export default Privacy;
